// don't hide navbar items
$navbar-breakpoint: 0;

@import "~bulma/bulma";
@import '~leaflet/dist/leaflet.css';

body {
  display: flex;
  flex-direction: column;
}

.navbar-brand {
  font-size: 1.4em;
  font-weight: 600;
  img {
    max-height: 50px;
  }
}

#map {
  height: calc(100vh - 3.25rem); // minus navbar height
  .leaflet-container {
    background-color:rgba(255,0,0,0.0);
  }
  .leaflet-control {
    max-width: 350px;
  }
  .leaflet-popup-pane {
    max-width: 350px;
    p {
      margin: 0;
    }
    span.current-network {
      color: '#D4AF7F';
      font-weight: '800';
    }
    span.seamless-network {
      color: '#99d6ff';
      font-weight: '800';
    }
  }
}

p {
  padding-bottom: .4em;
}